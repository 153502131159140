import React from "react";
import "./BackWhiteArrow.css";

const BackWhiteArrow = () => {
  return (
    <div className="arrow-white-ctn">
      <img
        src="/assets/img/back.png"
        alt="experiencegt back"
        className="arrow-img"
      />
    </div>
  );
};

export default BackWhiteArrow;
