import React from "react";
import Construction from "../components/Construction";
import "./ConstructionPage.css";

const ConstrutionPage = () => {
    return (
        <div className="body_construction">
            <Construction />
        </div>
    );
}

export default ConstrutionPage;