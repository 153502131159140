import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { Row, Col, Table, Button, Container } from "react-bootstrap";
import "./TourDetailPage.css";
import SubMenuNavbar from "../components/SubMenuNavbar";
import ReservationForm from "../components/ReservationForm";
import Image from "react-bootstrap/Image";

function TourDetailPage() {
  const navigate = useNavigate();
  const { tourName, subTourName } = useParams();
  const translations = useCustomTranslation();
  const [showForm, setShowForm] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const tourDetails =
  translations[
    subTourName === "detail" ? `tour-${tourName}-details` : `${subTourName}`
  ];

  useEffect(() => {
    if (translations && Object.keys(translations).length > 0) {
      if (!tourDetails || Object.keys(tourDetails).length === 0) {
        navigate("/construction");
      }
    }
  }, [translations, tourDetails, navigate]);

  if (!translations || Object.keys(translations).length === 0) {
    return <div>Loading...</div>;
  }

  const title = tourDetails?.title;
  const subtitle = tourDetails?.subtitle;
  const description = tourDetails?.description;
  const description1 = tourDetails?.description1;
  const description2 = tourDetails?.description2;
  const description3 = tourDetails?.description3;
  const additionalText1 = tourDetails?.additional_text1;
  const additionalText2 = tourDetails?.additional_text2;
  const price1Title = tourDetails?.price1_title;
  const price1Amount = tourDetails?.price1_amount;
  const price1Details = tourDetails?.price1_details || [];
  const price2Title = tourDetails?.price2_title;
  const price2Amount = tourDetails?.price2_amount;
  const price2Details = tourDetails?.price2_details || [];
  const price3Title = tourDetails?.price3_title;
  const price3Amount = tourDetails?.price3_amount;
  const price3Details = tourDetails?.price3_details || [];
  const price2Includes = tourDetails?.price2_includes || [];
  const day1Title = tourDetails?.day1_title;
  const day1 = tourDetails?.day1 || {};
  const day2Title = tourDetails?.day2_title;
  const day2 = tourDetails?.day2 || {};
  const includesTitle = tourDetails?.includes_title;
  const includesTitle2 = tourDetails?.includes_title2;
  const includes_title_descrip = tourDetails?.includes_title_descrip;
  const includes_descrip = tourDetails?.includes_descrip || [];
  const includes = tourDetails?.includes || [];
  const termsTitle = tourDetails?.terms_title;
  const terms = tourDetails?.terms || [];
  const notes = tourDetails?.notes || [];
  const images = tourDetails?.images || [];
  const toursInfo = tourDetails?.tours_info || [];
  const scheduleTitle = tourDetails?.schedule_title;
  const schedule = tourDetails?.schedule || [];
  const scheduleDescription = tourDetails?.schedule_description;
  const price1Description = tourDetails?.price1_description;
  const price2Description = tourDetails?.price2_description;
  const price3Description = tourDetails?.price3_description;
  const departureTimeLabel = tourDetails?.departure_time_label;
  const departureTime = tourDetails?.departure_time;
  const departureTimes = tourDetails?.departure_times || [];
  const returnTimeLabel = tourDetails?.return_time_label;
  const returnTime = tourDetails?.return_time;
  const reserveText = translations?.reserve_here || "Reserve Here";
  const importantInfoTitle = tourDetails?.important_info?.title;
  const importantInfoDetails = tourDetails?.important_info?.details || [];
  const departureTimeDownTitle = tourDetails?.departure_time_down_title;
  const departureTimeDown = tourDetails?.departure_time_down;
  const departureTimeBottomTitle = tourDetails?.departure_time_bottom_title;
  const departureTimeBottom = tourDetails?.departure_time_bottom;

  const handleReserveClick = (tour, price) => {
    setSelectedTour(tour);
    setSelectedPrice(price);
    setShowForm(true);
  };

  const handleFormSubmit = () => {
    setShowForm(false);
    setSelectedTour(null);
    setSelectedPrice(null);
  };
  const handleClickBack = () => {
    setShowForm(false);
  }

  return (
    <>
      {showForm ? (
        <ReservationForm
          tour={selectedTour}
          price={selectedPrice}
          showForm={showForm}
          setShowForm={setShowForm}
          onClickBack={handleClickBack}
          onFormSubmit={handleFormSubmit}
        />
      ) : (
        <div className="p-3">
          <Row className="justify-content-center">
            <Col xs={12} md={8} className="text-center">
              <SubMenuNavbar />
              <h1 className="wolf-font mt-3 orange-text title-text">{title}</h1>
              <p>{subtitle}</p>
              {description && <p>{description}</p>}
              {description1 && <p>{description1}</p>}
              {description2 && <p>{description2}</p>}
              {description3 && <p>{description3}</p>}
              {additionalText1 && (
                <h4 className="text-left font-weight-bold">
                  {additionalText1}
                </h4>
              )}
              {additionalText2 && (
                <h4 className="text-left font-weight-bold">
                  {additionalText2}
                </h4>
              )}
              {departureTimes.length > 0 && (
                <Container>
                  <Row className="align-items-center my-3">
                    <Col md={{ span: 2, offset: 2 }}>
                      <p className="text-center orange-text font-weight-bold">
                        {departureTimeLabel}
                      </p>
                      {departureTimes.map((time, index) => (
                        <p key={index} className="text-center text-dark">
                          {time}
                        </p>
                      ))}
                    </Col>
                  </Row>
                </Container>
              )}
              {price1Amount && (
                <>
                  <Row className="align-items-center my-3">
                    <Col className="mt-3 text-right">
                      <p className="text-uppercase orange-text mb-1">{price1Title}</p>
                      <h3 className="font-weight-bold mb-0">{price1Amount}</h3>
                    </Col>
                    <Col className="text-left">
                      <Button
                        variant="warning"
                        className="orange-gb mt-0"
                        onClick={() => handleReserveClick(title, price1Amount)}
                      >
                        {reserveText}
                      </Button>
                    </Col>
                  </Row>

                  {price2Amount && (
                    <>
                      <Row className="align-items-center my-3">
                        <Col className="mt-3 text-right">
                          <p className="text-uppercase orange-text mb-1">{price2Title}</p>
                          <h3 className="font-weight-bold mb-0">{price2Amount}</h3>
                        </Col>
                        <Col className="text-left">
                        </Col>
                      </Row>
                      <ul className="text-left">
                        {price2Details.map((detail, index) => (
                          <li key={index}>{detail}</li>
                        ))}
                      </ul>
                      <p className="text-left">{price2Description}</p>
                      {price2Includes.length > 0 && (
                        <Container className="px-5">
                          <h4 className="text-left orange-text font-weight-bold">
                            {includesTitle2}
                          </h4>
                          <ul className="text-left">
                            {price2Includes.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </Container>
                      )}
                    </>
                  )}
                  {departureTime && (
                    <Row className="text-center">
                      <Col>
                        <p className="text-right orange-text font-weight-bold">
                          {departureTimeLabel}{" "}
                          <span className="text-dark">{departureTime}</span>
                        </p>
                      </Col>
                      <Col>
                        <p className="text-left orange-text font-weight-bold">
                          {returnTimeLabel}{" "}
                          <span className="text-dark">{returnTime}</span>
                        </p>
                      </Col>
                    </Row>
                  )}

                  <ul className="text-left">
                    {price1Details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                  <p className="text-left">{price1Description}</p>
                </>
              )}
              {price3Amount && (
                <>
                  <Row className="align-items-center my-3">
                    <Col className="mt-3 text-right">
                      <p className="text-uppercase mb-1">{price3Title}</p>
                      <h3 className="font-weight-bold mb-0">{price3Amount}</h3>
                    </Col>
                    <Col className="text-left">
                      <Button
                        variant="warning"
                        className="orange-gb mt-0"
                        onClick={() => handleReserveClick(title, price3Amount)}
                      >
                        {reserveText}
                      </Button>
                    </Col>
                  </Row>
                  <ul className="text-left">
                    {price3Details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                  <p className="text-left">{price3Description}</p>
                </>
              )}

              {scheduleTitle && (
                <>
                  <h5 className="text-left orange-text font-weight-bold">
                    {scheduleTitle}
                  </h5>
                  <Table borderless className="text-left">
                    <tbody>
                      {schedule.map((time, index) => (
                        <tr key={index}>
                          <td>{time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <p className="text-left">{scheduleDescription}</p>
                </>
              )}

              {departureTimeDownTitle && departureTimeDown && (
                <>
                  <h5 className="text-left orange-text font-weight-bold">
                    {departureTimeDownTitle}
                  </h5>
                  <Table borderless className="text-left">
                    <tbody>
                        <tr>
                          <td>{departureTimeDown}</td>
                        </tr>
                    </tbody>
                  </Table>
                </>
              )}

              {departureTimeBottomTitle && departureTimeBottom && (
                <>
                  <h5 className="text-left orange-text font-weight-bold">
                    {departureTimeBottomTitle}
                  </h5>
                  <Table borderless className="text-left">
                    <tbody>
                        <tr>
                          <td>{departureTimeBottom}</td>
                        </tr>
                    </tbody>
                  </Table>
                </>
              )}

              {importantInfoTitle && (
                <>
                  <h4 className="text-left orange-text font-weight-bold">
                    {importantInfoTitle}
                  </h4>
                  <ul className="text-left">
                    {importantInfoDetails.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                </>
              )}

              <h5 className="text-left orange-text font-weight-bold">
                {day1Title}
              </h5>
              <Table borderless className="text-left">
                <tbody>
                  {Object.entries(day1).map(([time, activity]) => (
                    <tr key={time}>
                      <td>{time} hrs.</td>
                      <td>{activity}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {Object.keys(day2).length > 0 && (
                <>
                  <h5 className="text-left orange-text font-weight-bold">
                    {day2Title}
                  </h5>
                  <Table borderless className="text-left">
                    <tbody>
                      {Object.entries(day2).map(([time, activity]) => (
                        <tr key={time}>
                          <td>{time} hrs.</td>
                          <td>{activity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              {toursInfo.length > 0 && (
                <Container className="px-5">
                  {toursInfo.map((info, index) => (
                    <div key={index} className="my-4">
                      <h5 className="text-left orange-text font-weight-bold">
                        {info.title}
                      </h5>
                      <p>{info.description}</p>
                    </div>
                  ))}
                </Container>
              )}

              <Container className="px-5">
                <h4 className="text-left orange-text font-weight-bold">
                  {includes_title_descrip}
                </h4>
                <ul className="text-left">
                  {includes_descrip.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </Container>

              <Container className="px-5">
                <h4 className="text-left orange-text font-weight-bold">
                  {includesTitle}
                </h4>
                <ul className="text-left">
                  {includes.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </Container>
              <h4 className="text-left">{termsTitle}</h4>
              <ul className="text-left">
                {terms.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <ul className="text-left mt-3">
                {notes.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Col>

            <Col xs={12} md={4} className="text-center">
              <Row>
                {images.map((image, index) => (
                  <Col xs={12} key={index} className="py-3">
                    <Image src={image} fluid />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default TourDetailPage;
