import React from "react";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./ToursPage.css";

function ToursPage() {
  const translations = useCustomTranslation();
  const navigate = useNavigate();

  if (!translations || Object.keys(translations).length === 0) {
    return <div>Loading...</div>;
  }

  const tours = Object.keys(translations.tours);

  const handleTourClick = (tourName) => {
    if (
      [
        "tour-lanquin",
        "tour-pacaya",
        "tour-acatenango",
        "tour-el-paredon",
        "tour-chichicastenango",
        "tour-ciudad-de-guatemala"
      ].includes(tourName)
    ) {
      navigate(`/tours/${tourName}/detail`);
    } else {
      navigate(`/tours/${tourName}`);
    }
  };

  return (
    <div className="tours-page">
      <Container>
        <Row className="justify-content-center mb-4">
          <div className="logo-ctn">
            <img
              src="/assets/img/whitelogo.png"
              alt="White Logo"
              className="img-fluid"
            />
          </div>
        </Row>
        <Row className="justify-content-start mb-4">
          <Col xs={12}>
            <h1 className="orange-font wolf-font text-left mb-0">
              {translations.toursTitle}
            </h1>
          </Col>
        </Row>
        <Row>
          {tours.map((tour) => (
            <Col key={tour} xs={12} md={4} className="mb-4">
              <div className="tour-card" onClick={() => handleTourClick(tour)}>
                <img
                  src={translations.tours[tour]?.imgSrc}
                  alt={translations.tours[tour]?.name || "Tour image"}
                  className="img-fluid"
                />
                <h5 className="text-center orange-font mt-2">
                  {translations.tours[tour]?.name}
                </h5>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default ToursPage;
