import React, { useState } from "react";
import { Offcanvas, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import useCustomTranslation from "../hooks/useCustomTranslation";

function NavbarOffcanvas() {
  const [show, setShow] = useState(false);
  const translations = useCustomTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (!translations || Object.keys(translations).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Navbar fixed="top">
      <div className="navbar-button p-2 text-white fs-2" onClick={handleShow}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="start"
        className="bg-black text-white menu-container"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="img-menu-container">
              <img
                src="/assets/img/whitelogo.png"
                alt="ExperienceGT Logo"
                className="centered-logo"
              />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column text-white wolf-font menu-text-wolf">
            <Nav.Link
              as={Link}
              to="/"
              className="text-white"
              onClick={handleClose}
            >
              {translations.navbar.home}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/about-us"
              className="text-white"
              onClick={handleClose}
            >
              {translations.navbar.about}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/tours"
              className="text-white"
              onClick={handleClose}
            >
              {translations.navbar.tours}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/shuttle"
              className="text-white"
              onClick={handleClose}
            >
              {translations.navbar.shuttle}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              className="text-white"
              onClick={handleClose}
            >
              {translations.navbar.contact}
            </Nav.Link>
          </Nav>
          <div className="social-icons">
            <a
              className="text-white p-2 fs-3"
              href="https://www.facebook.com/Experience502gt"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              className="text-white p-2 fs-3"
              href="https://www.instagram.com/experience502gt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              className="text-white p-2 fs-3"
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a
              className="text-white p-2 fs-3"
              href="https://youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
}

export default NavbarOffcanvas;
