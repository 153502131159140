import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarOffcanvas from './components/NavbarOffcanvas';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import ToursPage from './pages/ToursPage';
import ShuttlePage from './pages/ShuttlePage';
import ContactPage from './pages/ContactPage';
import SubTourMenuPage from './pages/SubTourMenuPage';
import TourDetailPage from './pages/TourDetailPage';
import SecondSubMenuTourPage from './pages/SecondSubMenuTourPage';
import ConstrutionPage from './pages/ConstructionPage';

import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<><NavbarOffcanvas /><HomePage /></>} />
        <Route path="/home" element={<><NavbarOffcanvas /><HomePage /></>} />
        <Route path="/about-us" element={<><NavbarOffcanvas /><AboutUsPage /></>} />
        <Route path="/tours" element={<><NavbarOffcanvas /><ToursPage /></>} />
        <Route path="/shuttle" element={<><NavbarOffcanvas /><ShuttlePage /></>} />
        <Route path="/contact" element={<><NavbarOffcanvas /><ContactPage /></>} />
        <Route path="/tours/:tourName" element={<SubTourMenuPage />} />
        <Route path="/tours/:tourName/:subTourName" element={<TourDetailPage />} />
        <Route path="/tours/:tourName/second/:subTourName" element={<SecondSubMenuTourPage />} />
        <Route path="/construction" element={<ConstrutionPage />} />
      </Routes>
    </Router>
  );
}

export default App;
