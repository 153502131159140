import React from "react";
import "./WhiteLogo.css";

const WhiteLogo = () => {
  return (
    <div className="white-menu-logo">
      <img
        src="/assets/img/whitelogo.png"
        alt="whitelogo"
        className="img-fluid"
      />
    </div>
  );
};

export default WhiteLogo;
