import React from "react";
import { Link, useLocation } from "react-router-dom";
import BackArrow from "./BackArrow";
import WhiteLogo from "./WhiteLogo";
import BlackLogo from "./BlackLogo";
import "./SubMenuNavbar.css";
import { Navbar, Container } from "react-bootstrap";

const SubMenuNavbar = () => {
  const location = useLocation();
  console.log(location.pathname);

  return (
    <Navbar expand="lg" className="navbar-custom">
      <Container fluid className="position-relative d-flex align-items-center">
        <Navbar.Brand as={Link} to="/tours" className="navbar-brand-custom">
          <BackArrow />
        </Navbar.Brand>
        <div className="logo-container position-absolute start-50 translate-middle-x">
          {location.pathname.includes("/detail") ? (
            <BlackLogo />
          ) : (
            <WhiteLogo />
          )}
        </div>
      </Container>
    </Navbar>
  );
};

export default SubMenuNavbar;
