import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { Container, Row, Col } from "react-bootstrap";
import "./SubTourMenuPage.css";
import SubMenuNavbar from "../components/SubMenuNavbar";

function SubTourMenuPage() {
  const { tourName } = useParams();
  const translations = useCustomTranslation();
  const navigate = useNavigate();

  if (!translations || Object.keys(translations).length === 0) {
    return <div>Loading...</div>;
  }

  const tour = translations.tours[tourName];

  if (!tour || !tour.subTours) {
    return (
      <div className="subtour-menu-page">
        <SubMenuNavbar />
        <Container className="mt-5">
          <Row>
            <Col>
              <h1 className="wolf-font text-left mb-0">Tour not found</h1>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  const handleSubTourClick = (subTourName) => {
    if (
      subTourName === "antigua-miradores" ||
      subTourName === "antigua-clase-de-cocina" ||
      subTourName === "antigua-bicicleta"
    ) {
      navigate(`/tours/${tourName}/second/${subTourName}`);
    } else {
      navigate(`/tours/${tourName}/${subTourName}`);
    }
  };

  return (
    <div className="subtour-menu-page">
      <SubMenuNavbar />
      <Container className="mt-5">
        <Row>
          <Col md={8}>
            <h1 className="wolf-font text-left mb-0">{tour.name}</h1>
          </Col>
        </Row>
        <Row>
          {Object.keys(tour.subTours).map((subTour) => (
            <Col key={subTour} xs={12} md={4} lg={3} className="mb-4">
              <div className="subtour-card" onClick={() => handleSubTourClick(subTour)}>
                <img src={tour.subTours[subTour]?.imgSrc} alt={tour.subTours[subTour]?.name || "Subtour image"} className="img-fluid" />
                <h5 className="text-center mt-2 text-dark fw-bold">{tour.subTours[subTour]?.name}</h5>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default SubTourMenuPage;
