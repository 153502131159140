import React from "react";
import "./HomePage.css";

function HomePage() {
  return (
    <div className="video-background">
      <video
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "-1",
        }}
      >
        <source src="/assets/video/homevideo.mp4" type="video/mp4" />
      </video>
      <div className="content">
        <img
          src="/assets/img/whitelogo.png"
          alt="ExperienceGT Logo"
          className="centered-logo d-none d-md-block"
        />
      </div>
      <div style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#00000054",
          position: "absolute",
          top: "0",
          left: "0",
        }}></div>
    </div>
  );
}

export default HomePage;
