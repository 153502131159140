import React from "react";
import "./Construction.css";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { Link } from "react-router-dom";

const Construction = () => {

    const translations = useCustomTranslation();

    if (!translations || Object.keys(translations).length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="content_constru">
                <img className="logo_constru" src="/assets/img/whitelogo.png" alt="" />
                <h1 className="title_constru">
                    {translations.enConstruccion.titulo}
                </h1>
                <p className="descrip_constru">
                    {translations.enConstruccion.description}
                </p>
                <Link to="/home" className="button_more">
                    {translations.enConstruccion.txtButton}
                </Link>
            </div>
        </>
    );
}



export default Construction;