import React, { useState } from "react";
import BackWhiteArrow from "./BackWhiteArrow";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import useCustomTranslation from "../hooks/useCustomTranslation";
import "./ReservationForm.css";

const ReservationForm = ({ tour, price, showForm, setShowForm, onClickBack }) => {
  const translations = useCustomTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tour: tour,
    date: "",
    quantity: "",
    comments: "",
  });
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = process.env.REACT_APP_WA_API_ID;
    const phoneNumber = process.env.REACT_APP_WA_PHONE_NUMBER;
    const { name, email, tour, date, quantity, comments } = formData;
    const formattedMessage = `Name: ${name}, Email: ${email}, Tour: ${tour}, Date: ${date}, Quantity: ${quantity}, Comments: ${comments}, Price: ${price}`;

    try {
      const response = await axios.post(
        `https://graph.facebook.com/v19.0/${id}/messages`,
        {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: phoneNumber,
          type: "template",
          template: {
            name: "web",
            language: {
              code: "es_MX"
            },
            components: [{
              type: "body",
              parameters: [
                {
                  type: "text",
                  text: formattedMessage
                }
              ]
            }]
          }
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_WA_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );
      console.log("Message sent successfully", response);
      setShowForm(false);
      setShowMessage(true);
      setErrorMessage(null);
      setFormData({
        name: "",
        email: "",
        tour: tour,
        date: "",
        quantity: "",
        comments: "",
      });
    } catch (error) {
      console.error("Error sending message", error);
      setErrorMessage("Error sending message. Please try again.");
      setShowMessage(false);
    }
  };

  return (
    <div className="tours-page">
      <div onClick={onClickBack} className="p-5">
        <BackWhiteArrow />
      </div>
      <Container className="reservation-form my-5">
        {showMessage && (
          <Alert variant="success" onClose={() => setShowMessage(false)} dismissible>
            Message sent successfully!
          </Alert>
        )}
        {errorMessage && (
          <Alert variant="danger" onClose={() => setErrorMessage(null)} dismissible>
            {errorMessage}
          </Alert>
        )}
        {showForm && (
          <Row>
            <Col md={6} className="form-left">
              <h1 className="reservation-title">
                {translations?.reservation_title || "YOUR RESERVATION"}
              </h1>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>{translations?.name || "NAME"}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={translations?.enter_name || "Enter your name"}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label>{translations?.email || "E-MAIL"}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={translations?.enter_email || "Enter your email"}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formTour">
                  <Form.Label>{translations?.tour || "TOUR"}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={translations?.enter_tour || "Enter your tour"}
                    name="tour"
                    value={formData.tour}
                    onChange={handleChange}
                    disabled
                  />
                </Form.Group>
                <Form.Group controlId="formDate">
                  <Form.Label>{translations?.date || "DATE"}</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formQuantity">
                  <Form.Label>{translations?.quantity || "QUANTITY"}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={translations?.enter_quantity || "Enter quantity"}
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formComments">
                  <Form.Label>{translations?.comments || "COMMENTS"}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="comments"
                    value={formData.comments}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="image-button">
                  <img
                    src="/assets/img/submit.png"
                    alt="Submit"
                    className="full-image-button"
                  />
                </Button>
              </Form>
            </Col>
            <Col md={6} className="contact-info">
              <div className="logo">
                <img src="/assets/img/logo.png" alt="Experience GT" />
              </div>
              <address>
                Calzada Santa Lucía No. 3C
                <br />
                Antigua Guatemala
              </address>
              <div className="contact-details">
                <p>+502 4135-3685</p>
                <p>+502 5843-1874</p>
                <p>+502 7947-0110</p>
                <p>@experiencegt</p>
                <p>@experience502gt</p>
                <p>@experience.gt</p>
                <p>www.experience.gt</p>
                <p>experiencegt502@gmail.com</p>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ReservationForm;
