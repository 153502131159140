import React, { useState } from "react";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { Row, Col, Button, Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import ReservationForm from "../components/ReservationForm";
import "./ShuttlePage.css";

function ShuttlePage() {
  const translations = useCustomTranslation();
  const [showForm, setShowForm] = useState(false);
  const [selectedShuttle, setSelectedShuttle] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  if (!translations || Object.keys(translations).length === 0) {
    return <div>Loading...</div>;
  }

  const shuttles = translations.shuttles || [];

  if (shuttles.length === 0) {
    return <div>No shuttles available</div>;
  }

  const handleReserveClick = (shuttle, price) => {
    setSelectedShuttle(shuttle);
    setSelectedPrice(price);
    setShowForm(true);
  };

  const handleClickBack = () => {
    setShowForm(false);
  };

  return (
    <>
      {showForm && (
        <ReservationForm
          tour={selectedShuttle}
          price={selectedPrice}
          showForm={showForm}
          setShowForm={setShowForm}
          onClickBack={handleClickBack}
        />
      )}
      {!showForm && (
        <>
          {shuttles.map((shuttle, index) => (
            <div key={index}>
              <Image src={shuttle.image} fluid className="shuttle-image" />
              <Container>
                <Row className="justify-content-center mt-5">
                  <Col xs={12} md={6} className="text-left">
                    {shuttle.destination.map((destination, index) => (
                      <div key={index} className="my-4">
                        <h4 className="orange-text font-weight-bold">
                          {destination.title}
                        </h4>
                        {destination.schedule.map((schedule, idx) => (
                          <div key={idx}>
                            <span>{schedule.departure}</span>
                            <span className="mx-3 font-weight-bold size-1-4">
                              {schedule.price}
                            </span>
                            {schedule.price && (
                              <div>
                                <Button
                                  variant="warning"
                                  className="my-3"
                                  onClick={() =>
                                    handleReserveClick(
                                      shuttle.name,
                                      schedule.price
                                    )
                                  }
                                >
                                  Reserve Here
                                </Button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </Col>

                  <Col xs={12} md={6} className="text-right">
                    {shuttle.price && (
                      <div>
                        <h2>{shuttle.price}</h2>
                        <Button
                          variant="warning"
                          className="my-3"
                          onClick={() =>
                            handleReserveClick(shuttle.name, shuttle.price)
                          }
                        >
                          Reserve Here
                        </Button>
                      </div>
                    )}
                    <h3>{shuttle.terms_title}</h3>
                    <ul>
                      {shuttle.terms.map((term, idx) => (
                        <li key={idx}>{term}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default ShuttlePage;
