import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { Container, Row, Col } from "react-bootstrap";
import "./SecondSubMenuTourPage.css";
import SubMenuNavbar from "../components/SubMenuNavbar";

function SecondSubMenuTourPage() {
  const { tourName, subTourName } = useParams();
  const translations = useCustomTranslation();
  const navigate = useNavigate();

  if (!translations || Object.keys(translations).length === 0) {
    return <div>Loading...</div>;
  }

  const tour = translations.tours[tourName];
  const subTour = tour?.subTours[subTourName];
  const secondSubTours = subTour?.secondSubTours;

  if (!secondSubTours) {
    return (
      <div className="second-subtour-menu-page">
        <SubMenuNavbar />
        <Container className="mt-5">
          <Row>
            <Col>
              <h1 className="wolf-font text-left mb-0">Sub-tour not found</h1>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  const handleSecondSubTourClick = (secondSubTourName) => {
    navigate(`/tours/${tourName}/${secondSubTourName}`);
  };

  return (
    <div className="second-subtour-menu-page">
      <SubMenuNavbar />
      <Container className="mt-5">
        <Row>
          <Col md={8}>
            <h1 className="wolf-font text-left mb-0">{subTour.name}</h1>
          </Col>
        </Row>
        <Row>
          {Object.keys(secondSubTours).map((secondSubTour) => (
            <Col key={secondSubTour} xs={12} md={6} lg={4} className="mb-4">
              <div className="subtour-card" onClick={() => handleSecondSubTourClick(secondSubTour)}>
                <img src={secondSubTours[secondSubTour]?.imgSrc} alt={secondSubTours[secondSubTour]?.name || "Second Subtour image"} className="img-fluid" />
                <h5 className="text-center mt-2 text-dark fw-bold">{secondSubTours[secondSubTour]?.name}</h5>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default SecondSubMenuTourPage;
