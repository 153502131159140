import React from "react";
import "./BackArrow.css";

const BackArrow = () => {
  return (
    <div className="arrow-ctn">
      <img
        src="/assets/img/backNegro.png"
        alt="experiencegt back"
        className="arrow-img"
      />
    </div>
  );
};

export default BackArrow;
