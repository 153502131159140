import React from "react";
import useCustomTranslation from "../hooks/useCustomTranslation";

function AboutUsPage() {
  const translations = useCustomTranslation();

  if (!translations || Object.keys(translations).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-100vh orange-bg">
      <div className='about-img-container w-100'>
        <img
          src="/assets/img/aboutUs.png"
          alt="ExperienceGT Logo"
          className="full-width-img"
        />
      </div>
      <div className="container-fluid orange-bg h-100 w-100 py-4">
        <h1 className="mb-3 wolf-font text-center">{translations.aboutTitle}</h1>
        <p className="text-center">{translations.aboutText1}</p>
        <p className="text-center">{translations.aboutText2}</p>
        <p className="text-center">{translations.aboutText3}</p>
        <p className="text-center">{translations.aboutText4}</p>
        <footer>
          <h3 className="text-center mt-4">"{translations.aboutFooter}"</h3>
        </footer>
      </div>
    </div>
  );
}

export default AboutUsPage;
