import { useState, useEffect } from "react";
import i18n from "i18next";

const useCustomTranslation = () => {
  const [translations, setTranslations] = useState({});
  const lng = i18n.language || "en";

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await fetch(`/locales/${lng}/translation.json`);
        const data = await response.json();
        setTranslations(data);
      } catch (error) {
        console.error("Error fetching translation data:", error);
      }
    };
    fetchTranslations();
  }, [lng]);

  return translations;
};

export default useCustomTranslation;
